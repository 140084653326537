@import 'import';
$root: '.NewResultRefinements';

#{$root} {
  &__row {
    overflow: auto hidden;
    width: 100%;

    @include make-row();

    &:first-child {
      border-bottom: 1px solid $gray-010;

      @include media-breakpoint-up(md) {
        border: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {
      overflow-x: auto;

      &.-other {
        #{$root}__label {
          @include sr-only();
        }

        #{$root}__refinements {
          overflow: hidden hidden;
          margin-top: rem(20);
        }
      }
    }

    @include media-breakpoint-up(xxl) {
      &.-other {
        flex-wrap: nowrap;
      }
    }
  }

  &__label {
    @include make-col-ready();
    @include make-col(12);

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include font($size: rem(13), $weight: 'normal', $line-height: rem(20));

    margin-bottom: rem(9);

    @include media-breakpoint-down(md) {
      &:not(:first-child) {
        @include sr-only();
      }
    }

    @include media-breakpoint-up(xl) {
      @include make-col(2);
    }

    @include media-breakpoint-up(xxl) {
      @include make-col(1.7);
    }
  }

  &__filterContents {
    float: left;
    width: 100%;
    padding-right: 10px;
  }

  &__filtersIconTitle {
    display: inline;
    vertical-align: middle;

    @include font($size: rem(15), $weight: bold, $line-height: rem(21));
  }

  &__resetAllFilters {
    @include font($size: rem(15), $weight: normal, $line-height: rem(24));

    float: right;
  }

  &__refinements {
    @include make-col-ready();
    @include make-col(12);

    position: relative;
    margin-bottom: rem(17);

    @include media-breakpoint-up(md) {
      position: static;
    }
  }

  //     -ms-overflow-style: -ms-autohiding-scrollbar;

  &__title {
    text-transform: uppercase;
  }

  &__newFilterPrimaryRow {
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__newFilterSecondaryRow {
    display: flex;
    justify-content: center;

    .resetContainer {
      display: flex;
      justify-content: space-between;

      .AccessibilityRefinement {
        margin-right: 450px;

        @include media-breakpoint-up(lg) {
          margin-right: 658px;
        }

        @include media-breakpoint-down(sm) {
          margin-right: 36px;
        }

        label {
          width: 176px;

          @include media-breakpoint-up(lg) {
            width: 210px;
          }

          @include media-breakpoint-down(sm) {
            width: 163px;
          }
        }
      }

      .resetAllFilters {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0;

        button {
          color: $grey-text;
        }
      }
    }

    @media (max-width: $container-sm-width) and (orientation: landscape) {
      justify-content: unset;
      width: 330px;
      margin: 0 auto;
    }

    @include media-breakpoint-down(sm) {
      justify-content: unset;
    }
  }

  &__newFilterContainer {
    display: flex;

    .primaryContainer {
      display: flex;

      box-sizing: border-box;
      width: 810px;
      margin-right: 20px;

      background-color: $white-FC;
      border: 1px solid $gray-510;
      border-radius: 3px;

      .filter-button-container {
        width: 50%;
      }

      &.lightBorder {
        border: 1px solid $white-E9;

        .filter-button-container:not(.-open) {
          opacity: 0.6;
        }
      }

      @include media-breakpoint-down(md) {
        width: 610px;
      }
    }

    .secondaryContainer {
      cursor: pointer;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      padding: 10.5px;

      text-align: center;

      background-color: $white;
      border: 1px solid $gray-510;
      border-radius: 3px;

      @include font($size: rem(15), $weight: bold, $line-height: rem(21));

      .Icon {
        width: 15.29px;
        height: 15.29px;
        color: $black;

        @include media-breakpoint-up(lg) {
          width: 19.11px;
          height: 19.11px;
        }
      }

      @include media-breakpoint-up(md) {
        float: unset;
        width: 90px;
      }

      @include media-breakpoint-down(md) {
        width: 56px;
        padding: 5px;
      }

      .filtersIconTitle {
        display: inline;

        width: 33px;
        margin: 0;

        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: $grey-text;
        text-align: center;
        letter-spacing: 0;
        vertical-align: middle;

        @include media-breakpoint-up(lg) {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
}
